<script>
import AvatarCropper from "vue-avatar-cropper";
import { required, email } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { VueTelInput } from 'vue-tel-input';

export default {
  props : ["passwordInput"],
  components: { AvatarCropper,Multiselect ,VueTelInput},
  data() {
    return {
      showCropper: false,
      processing_image: false,
      show: true,
      uploadURL: this.$axios.defaults.baseURL + "/my-profile-image",
      locations: [],
      areas: [],
      workArea: [],
      phoneError : true,
    };
  },
    validations: {
      user: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        // password: {
        //   required,
        // },
        phone:{
          required,
        },
        locations: {  
          required
        },
        work_area_role: {
          required,
        },
        work_area: {
          required,
        },
        experience_level : {
          required,
        }
      },
     
    },
  computed: {
    user: {
      get() {
        return this.$store.state.userModule.user;
      },
      set(value) {
        this.$store.commit("updateUser", value);
      },
    },
  },
  mounted() {
    if(this.$route.name != "edit_profile_manager"){
      this.fetchLocations();
    }
  },

  created(){
      
  },
  methods: {
    checkPhone(phone)
    {
      if(this.user.phone)
      this.phoneError = phone.valid;
    },
    fetchLocations() {
      this.$axios.get("/restaurant/location/index").then((response) => {
        let data = response.data.payload;
        this.locations = data;
        if(this.user.locations.length>0){
          this.locations.forEach((location) => {
            let locIndex = this.user.locations.findIndex(loc => location.id == loc.id);
            if(locIndex > -1){
              this.user.locations[locIndex] = location;
              this.fetchArea(location);

              location.areas.forEach((area) => {
                this.user.work_area.forEach((work_area) => {
                  if(area.id == work_area.id){
                    this.showRole(area);
                  }
                });
              })
            }
          })
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      }).finally(function () {
      });
    },

    handleUploading(form, xhr) {
      this.processing_image = true;
    },
    handleUploaded(response) {
      this.processing_image = false;

      if (response) {
        this.user.avatar = response;
        this.triggerSwal("Image Uploaded Successfully!", "success");
      }
    },
    handleCompleted(response, form, xhr) {
      this.processing_image = false;
    },
    handlerError(message, type, xhr) {
      this.processing_image = false;
      this.triggerSwal(
        "There is a problem in updating your profile image.","error");
    },

    checkValidate()
    {
      this.$v.$touch();
    },
    validateErorr()
    {
     return this.$v.$invalid;   
    },
    

    //methods for Locations and workAreas
    fetchArea(e) {
      this.areas.push(...e.areas);
      if (this.areas.length == 0) {
        this.triggerSwal(
          "To procced further please create work area against this location",
          "error"
        );
      }
    },
    removeArea(e) {
      this.areas = this.areas.filter((item) => item.location_id != e.id);
    },
    showRole(e) {
      this.workArea.push(...e.area_role);
    },
    removeRole(e) {
      this.workArea = this.workArea.filter((item) => item.area_id != e.id);
    },
    numberFormat(e)
    {
        return this.user.employee.experience_level = e.substring(0,2);
    }
  },

  middleware: "authentication",
};
</script>

<template>
  <div class="my-form">
    <div class="row">
        <b-form>
          <div class="row">
            <div class="col-md-4 disp-flex align__items__center mt-2 mb-2">
              <div>
                <div class="card vue-avatar-cropper-demo text-center">
                  <div class="card-body">
                    <img
                      :src="user.avatar==null ? user.avatar_path : assets_url + user.avatar"
                      class="card-img avatar"
                    />
                    <p class="mt-4 py-5" v-if="user.avatar==null && user.avatar_path==null">Upload Image</p>
                  </div>
                  <avatar-cropper
                    @uploading="handleUploading"
                    @uploaded="handleUploaded"
                    :toBlob="true"
                    @completed="handleCompleted"
                    :labels="{ submit: 'Upload', cancel: 'Cancel' }"
                    @error="handlerError"
                    trigger="#pick-avatar"
                    :upload-url="uploadURL"
                  />
                  <!-- :upload-headers="uploadHeaders" -->

                  <button class="btn btn-primary btn-sm" id="pick-avatar" >
                    <!-- <span> Select a new image </span> -->
                  </button>
                </div>
              </div>
              <div class="ml-2" v-if="processing_image">
                <span class="spinner-grow spinner-grow-sm"></span>
                <span>Profile Image is Uploading ...</span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-md-row flex-column gap-3">
            <div class="w-100">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="input-group-2"
                        label="Name"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="user.name"
                          placeholder="Enter Name"
                          required
                          :class="{
                              'is-invalid': $v.user.name.$error,
                            }"
                        ></b-form-input>

                        <div v-if="$v.user.name.$error" class="invalid-feedback" >
                            <span v-if="!$v.user.name.required"
                              >Name is required.</span>
                          </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3" v-if="passwordInput!=true" >
                  <div class="align-items-center d-flex gap-2">
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="example-password"
                        label="Password"
                        label-for="password"
                      >
                        <b-form-input
                          id="password"
                          v-model="user.password"
                          placeholder="Enter Password"
                          type="password"
                          :class="{
                            'is-invalid': $v.user.password.$error,
                          }"
                        ></b-form-input>
                        <div v-if="$v.user.password.$error" class="invalid-feedback" >
                            <span v-if="!$v.user.password.required"
                              >Password is required.</span>
                          </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="example-tel"
                    label="Experience"
                    label-for="experience-level"
                    class="mb-3"
                  >
                    <b-form-input :formatter="numberFormat" type="number" placeholder="Enter Experience"  min="1" 
                      v-model="user.experience_level" :class="{
                        'is-invalid': $v.user.experience_level.$error,
                      }"></b-form-input>

                    <div
                      v-if="!$v.user.experience_level.required"
                      class="invalid-feedback"
                    >
                      Experience is required.
                    </div>
                  </b-form-group>
                </div>

                <div class="col-md-12 mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="form-field__wrapper w-100">
                      <label>Select Location</label>
                        <multiselect
                        :class="{'is-invalid': $v.user.locations.$error,}" 
                          v-model="user.locations"
                          :options="locations"
                          label="name"
                          track-by="id"
                          :multiple="true"
                          @select="fetchArea($event)"
                          @remove="removeArea($event)"
                        ></multiselect>
                        <div v-if="$v.user.locations.$error" class="invalid-feedback" >
                            <span v-if="!$v.user.locations.required">
                              Location is required.</span>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3" >
                  <label>Select Role/Position</label>
                  <multiselect
                    :class="{
                      'is-invalid': $v.user.work_area_role.$error,
                    }"
                    v-model="user.work_area_role"
                    :options="workArea"
                    label="name"
                    track-by="id"
                    :multiple="true"
                  ></multiselect>
                  <span class="span">You can select multiple role/position</span>
                  <div
                    v-if="!$v.user.work_area_role.required"
                    class="invalid-feedback"
                  >
                    Role/Position is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="align-items-center d-flex gap-2">
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="example-email"
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          placeholder="Enter Email"
                          v-model="user.email"
                          type="email"
                          :class="{
                              'is-invalid': $v.user.email.$error,
                            }"
                        ></b-form-input>
                        <div v-if="$v.user.email.$error" class="invalid-feedback" >
                            <span v-if="!$v.user.email.required">
                              Email is required.</span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="align-items-center d-flex gap-2">
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="example-tel"
                        label="Phone Number"
                        label-for="tele"
                        class="mb-3"
                      >
                        <vue-tel-input v-model="user.phone" :autoFormat="true" :dropdownOptions="{showFlags:true, showSearchBox:true}"  :mode="'international'" @validate="checkPhone($event)" :validCharactersOnly="true"
                        :class="{ 'is-invalid': $v.user.phone.$error,}" class="vue-tel-input-height"></vue-tel-input>
                          <div v-if="$v.user.phone.$error" class="invalid-feedback" >
                            <span v-if="!$v.user.phone.required">
                              Phone is required.</span>
                          </div>
                          <div  v-if="!phoneError"
                            class="text-danger mt-1"
                          >
                            Invalid Phone Number.
                          </div>
                        
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-3" >
                  <label>Work Area</label>
                  <div class="">
                    <multiselect
                      :class="{ 'is-invalid': $v.user.work_area.$error }"
                      v-model="user.work_area"
                      :options="areas"
                      label="name"
                      track-by="id"
                      :multiple="true"
                      @select="showRole($event)"
                      @remove="removeRole($event)"
                    ></multiselect>

                    <div
                      v-if="!$v.user.work_area.required" class="invalid-feedback">
                      Work Area is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SubmitButton text="Update Profile" @clicked="$emit('submitUserData')" v-if="passwordInput==true" ></SubmitButton>
        </b-form>
    </div>
  </div>
</template>
<style scoped>
.my-form .vue-avatar-cropper-demo .card-body{
 z-index: 2;
}
.my-form .vue-avatar-cropper-demo {
    height: 250px;
    width: 250px;
    position: relative;
}
.my-form #pick-avatar{
  position: absolute;
  height:100%;
  width: 100%;
  background: transparent;
  color: #222;
  z-index: 3;
    border: 1px solid #f251b0 !important;
}
.my-form #pick-avatar span{
 
  z-index: 0;

}

.my-form button#pick-avatar:before {
    position: absolute;
    content: '\ea3d';
    font-family: unicons-line;
    font-size: 18px;
    top: 11px;
    z-index: 999;
    width: 20px;
    height: 20px;
    right: 10px;
} 
.vue-tel-input-height{
    height: 38px !important;
}
</style>